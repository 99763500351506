<template>
  <div class="space-y-4">
    <section class="space-y-10">
      <QuickInfo />
      <article>
        <asom-card>
          <asom-alert v-if="error" variant="error" :error-message="error" />
          <section class="">
            <asom-form-field
              required
              :label="'Source GTM/TUK'"
              :state="inputStates('formData.gtmId')"
              error="Please select a GTM/TUK"
            >
              <asom-input-radio-group
                class="pt-3"
                horizontal
                v-model="formData.gtmId"
                :options="gtmOptions"
                :state="inputStates('formData.gtmId')"
              />
            </asom-form-field>
            <asom-form-field
              required
              class="md:w-1/2"
              :label="'Purpose'"
              :state="inputStates('formData.purpose')"
              error="Please select a purpose"
            >
              <asom-input-text
                :state="inputStates('formData.purpose')"
                v-model="formData.purpose" 
              />
            </asom-form-field>
            <asom-form-field
              required
              :label="'Method'"
              :state="inputStates('formData.method')"
              error="Please select a method"
            >
              <asom-input-radio-group
                class="pt-3"
                horizontal
                v-model="formData.method"
                :options="methodOptions"
                :state="inputStates('formData.method')"
              />
            </asom-form-field>
            <asom-form-field
              v-if="formData.method == 'other'"
              class="md:w-1/2"
              required
              :label="'Other method'"
              :state="inputStates('formData.otherMethod')"
              error="Please specify method"
            >
              <asom-input-text v-model="formData.otherMethod" />
            </asom-form-field>
            <asom-form-field
              class="md:w-1/2"
              :label="'Remarks'"
            >
              <asom-input-textarea v-model="formData.remarks" />
            </asom-form-field>
          </section>
          <template #footer>
            <div class="col-span-3 flex justify-between flex-1">
              <asom-button
                variant="link"
                text="Cancel"
                @click="$router.push({ name: 'CoinFlush Main' })"
              />
              <asom-button text="Confirm" @click="onConfirmClicked" />
            </div>
          </template>
        </asom-card>
      </article>
    </section>
    <asom-modal title="Confirm" v-model="showModal" :dismissible="false">
      <p class="pt-4">Are you sure you would like to proceed?</p>
      <div class="flex flex-row-reverse pt-4">
        <div class="pl-4">
          <asom-button
            @click="onSubmit"
            :disabled="isSubmitting"
            :loading="isSubmitting"
            text="OK"
          />
        </div>
        <div>
          <asom-button
            @click="onCloseModal"
            text="Cancel"
            variant="secondary"
          />
        </div>
      </div>
    </asom-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import inputStates from "@/mixins/inputStates";
import { createCoinFlushForm } from "@/services/cashManagement.service";
import QuickInfo from "../../_QuickInfo.vue";
export default {
  mixins: [inputStates],
  setup: () => ({ v$: useVuelidate() }),
  components: {
    QuickInfo,
  },
  data() {
    return {
      isSubmitting: false,
      showModal: false,
      error: null,
      formData: {
        gtmId: null,
        purpose: null,
        method: null,
        otherMethod: null,
        remarks: "",
      },
    };
  },
  validations() {
    let validations = {
      formData: {
        gtmId: {
          required,
        },
        purpose: {
          required,
        },
        method: {
          required,
        },
      },
    };
    if (this.formData.method === 'other') {
      validations.formData['otherMethod'] = {
        required
      }
    }
    return validations;
  },
  computed: {
    ...mapGetters({
      afcId: "cashManagement/stationCashAccounts/afcId",
      gtmOptions: "cashManagement/stationCashAccounts/gtmOptions",
      rosterPlanDate: 'currentRoster/currentRosterPlanDate',
      rosterDws: 'currentRoster/currentRosterShift',
    }),
    purposeOptions() {
      return [
        {
          label: "Coin hopper replenishment",
          value: "Coin hopper replenishment",
        },
        {
          label: "Other",
          value: "Other",
        },
      ];
    },
    methodOptions() {
      return [
        {
          label: "Flushing",
          value: "flushing",
        },
        {
          label: "Other",
          value: "other",
        },
      ];
    },
  },
  methods: {
    onConfirmClicked() {
      this.v$.$reset();
      this.v$.$touch();
      if (this.v$.formData.$invalid) {
        this.error = "Please complete all required fields.";
        this.$scrollTop();
      } else {
        this.showModal = true;
      }
    },
    async onSubmit() {
      let isFlushing = this.formData.method == "flushing" ? true : false;
      let otherMethod =
        this.formData.method == "other" ? this.formData.otherMethod : "";
      this.showModal = false;
      this.error = null;
      this.isSubmitting = true;
      const result = await createCoinFlushForm({
        afcAccountId: this.afcId,
        gtmAccountId: this.formData.gtmId,
        purpose: this.formData.purpose,
        isFlusing: isFlushing,
        otherMethod: otherMethod,
        remarks: this.formData.remarks,
        rosterPlanDate: this.rosterPlanDate,
        rosterDWS: this.rosterDws,
      });
      this.isSubmitting = false;
      if (!result.success) {
        this.error = result.payload;
        this.showModal = false;
        this.$scrollTop();
      } else {
        this.$router.push({ name: "CoinFlush Main" });
      }
    },
    onCloseModal() {
      this.showModal = false;
    },
  },
};
</script>
